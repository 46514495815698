@import url("~leaflet/dist/leaflet.css");
@import url("~leaflet-draw/dist/leaflet.draw.css");
@import url("~bootstrap/scss/bootstrap.scss");
@import url("~react-toastify/dist/ReactToastify.css");

@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("./assets/fonts/segoe/Segoe UI.woff") format("woff");
}
@font-face {
  font-family: "Segoe UI Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Italic"),
    url("./assets/fonts/segoe/Segoe UI Italic.woff") format("woff");
}
@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold"),
    url("./assets/fonts/segoe/Segoe UI Bold.woff") format("woff");
}
@font-face {
  font-family: "Segoe UI Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold Italic"),
    url("./assets/fonts/segoe/Segoe UI Bold Italic.woff") format("woff");
}

:root {
  --bs-body-font-family: "Segoe UI";
  --color-primary: #282933;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  height: 3px;
  --modal-width: 1200px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: lightgray;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: var(--color-primary) !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.modal-content {
  margin: 0 !important;
}

.areaCheck {
  padding: 8px;
}

.modal-1280 {
  max-width: 1440px !important;
  margin-top: 50px !important;
}
