@use "../../../../../../node_modules/bootstrap/scss/bootstrap";

.leaflet-div-icon {
  background: transparent;
  border: none;
}
.location-pin-img {
  width: 32px;
  height: 32px;
}

.leaflet-marker-icon .number {
  position: relative;
  top: -30px;
  font-size: 14px;
  left: 2px;
  font-weight: bold;
  width: 20px;
  text-align: center;
  color: white;
}

#daydragdropmap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.drag-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: #2a2b36;
  width: 400px;
  height: 100%;
  font-size: 14px;
  color: black;
}
.drag-item-container {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drag-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  background-color: #4c4f54;
  color: white;
  border-radius: 6px;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }
}

.costchange-negative {
  @extend .text-success;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
}
.costchange-positive {
  @extend .text-danger;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
}
