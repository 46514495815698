#day_map {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
}
.area-box {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    background-color: #f4f4f4;
    width: 200px;
    height: 100%;
    color: black;
    font-weight: bold;
    overflow: hidden;
    overflow-y: auto;
}

.areaCheck {
    padding: 8px;
}
.areaCheck:hover {
    cursor: pointer;
    background-color: lightgray;
}

.leaflet-right {
    right: 200px;
    display: none;
}
