.chrome-warn {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
  max-width: 800px;
  padding: 20px;
  &__title {
    color: white;
    margin-bottom: 20px;
  }
  &__text {
    color: white;
    margin-bottom: 200px;
  }
}
