@use "../../../../../../node_modules/bootstrap/scss/bootstrap";

.daysdrag-title {
  color: white;
  font-size: 16px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.daysdrag-items {
  overflow: hidden;
  overflow-y: auto;
}
.daysdrag-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: #2a2b36;
  width: 100%;
  height: 100%;
  font-size: 14px;
}
.daysdrag-items-container {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 100%;
}

.daysdrag-item-container {
  width: 15%;
  height: 100%;
}
.daysdrag-items {
  height: 77%;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;
}

.daydrags-drag-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px 10px;
  background-color: #4c4f54;
  color: white;
  border-radius: 6px;
  font-size: 11px;
  margin: 20px 0;
  &:hover {
    cursor: pointer;
  }
}

.costchange-negative {
  @extend .text-success;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
}
.costchange-positive {
  @extend .text-danger;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
}
